@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif, "Segoe UI";
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif, "Segoe UI";
  font-size: 19px;
  /*   text-align: center; */

  /*     font-weight: bold; */
}

a {
  text-decoration: none;
}

.nav-area {
  /*  color: white; */
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
  /*   height: 100px; */
}

.logo {
  text-decoration: none;
  font-size: 20px;
  color: inherit;
  margin-right: 24px;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 16px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  text-decoration: none;
  border: none;
  font-size: inherit;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.6rem 1em;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow:after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}
